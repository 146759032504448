import React from 'react';
import { Container, Heading, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export const PageContainer = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const headerTextColor = useColorModeValue('#383332', '#fdfdff');
  const headerHoverTextColor = useColorModeValue('#171514', '#e6e2fb');

  return (
    <Container maxW="full" paddingTop="5" paddingBottom="10">
      <Heading
        fontSize="2xl"
        color={headerTextColor}
        textAlign="center"
        letterSpacing="-0.6px"
        _hover={{ color: headerHoverTextColor }}
      >
        <span style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <span style={{ marginRight: '8px' }}>☕</span>
          Block.cafe
        </span>
      </Heading>
      {children}
    </Container>
  );
};
