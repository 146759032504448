import React, { useCallback, useMemo, useState } from 'react';
import { PageContainer } from 'components/page-container';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { useThemeColors } from 'hooks/use-theme-colors';
import { ChainId, defaultChainId } from 'utils/chains';
import { useNavigate } from 'react-router-dom';
import { isValidEvmAddress } from 'utils/evm-address.utils';

export const ContractDebuggerInputScreen = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [inputAddress, setInputAddress] = useState<string | null>(null);
  const [inputChainId, setInputChainId] = useState<ChainId>(defaultChainId);
  const {
    buttonBgColor,
    buttonTextColor,
    containerBgColor,
    containerBorderColor,
    textColor,
    textLinkColor,
    textInputBgColor,
    textInputBorderColor,
    textInputPlaceholderColor,
  } = useThemeColors();

  const onSubmit = useCallback(() => {
    if (!isValidInputAddress) {
      return;
    }
    navigate(`/contract-debugger/${inputChainId}/${inputAddress}`);
  }, [inputChainId, inputAddress]);

  const isValidInputAddress = useMemo(() => {
    return isValidEvmAddress(inputAddress);
  }, [inputAddress]);

  const onChangeInputAddress = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.trim();

    // If input is cleared, just reset all state:
    if (!rawValue) {
      setErrorMessage(null);
      setInputAddress(null);
      return;
    }

    if (!isValidEvmAddress(rawValue)) {
      setErrorMessage("That doesn't appear to be a valid EVM address.");
      setInputAddress(rawValue);
    } else {
      setErrorMessage(null);
      setInputAddress(rawValue);
    }
  }, []);

  return (
    <PageContainer>
      <Center>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          mt="5"
          paddingTop="5"
          paddingBottom="5"
          paddingLeft="8"
          paddingRight="8"
          width="100%"
          maxW="700px"
        >
          <form onSubmit={onSubmit}>
            <FormControl isRequired isInvalid={!!errorMessage} marginBottom="5">
              <Box flex="1">
                <Center marginTop="3">
                  <Text fontSize="xl" color={textColor}>
                    Enter a smart contract address to debug:
                  </Text>
                </Center>
              </Box>
              <HStack spacing={'0'} marginTop="4" maxW="800px" marginLeft="auto" marginRight="auto">
                <InputGroup size="lg">
                  <Input
                    size="lg"
                    autoFocus
                    autoCorrect="false"
                    autoComplete="off"
                    placeholder="0x..."
                    name="address"
                    type="text"
                    data-lpignore="true"
                    value={inputAddress || ''}
                    onChange={onChangeInputAddress}
                    borderRightRadius={0}
                    backgroundColor={textInputBgColor}
                    borderColor={textInputBorderColor}
                    _placeholder={{ color: textInputPlaceholderColor }}
                  />
                  {inputAddress && isValidInputAddress && (
                    <InputRightElement>
                      <CheckIcon color="green.500" />
                    </InputRightElement>
                  )}
                </InputGroup>
                <Button
                  colorScheme="purple"
                  backgroundColor={buttonBgColor}
                  color={buttonTextColor}
                  size="lg"
                  aria-label="Search"
                  type="submit"
                  borderLeftRadius={0}
                >
                  Debug
                </Button>
              </HStack>
              {errorMessage && (
                <Center>
                  <FormErrorMessage marginTop="5">
                    <WarningTwoIcon marginRight="2" />
                    {errorMessage}
                  </FormErrorMessage>
                </Center>
              )}
            </FormControl>
          </form>
          <Box flex="1">
            <Center>
              <Text fontSize="xl" color={textColor}>
                or{' '}
                <span
                  style={{ cursor: 'pointer', color: textLinkColor }}
                  onClick={() =>
                    navigate('/contract-debugger/137/0x9BdB5fcc80A49640c7872ac089Cc0e00A98451B6')
                  }
                >
                  try an example
                </span>
              </Text>
            </Center>
          </Box>
        </Box>
      </Center>
    </PageContainer>
  );
};
