import React from 'react';
import { PageContainer } from 'components/page-container';
import { Box, Center, Text } from '@chakra-ui/react';
import { useThemeColors } from 'hooks/use-theme-colors';

export const AboutScreen = () => {
  const { containerBgColor, containerBorderColor, textColor } = useThemeColors();

  return (
    <PageContainer>
      <Center>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          mt="5"
          padding="5"
          width="100%"
          maxW="640px"
        >
          <Text fontSize="lg" color={textColor}>
            TODO
          </Text>
        </Box>
      </Center>
    </PageContainer>
  );
};
