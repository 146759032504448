import { useMemo } from 'react';
import { ChainId, defaultChainId, getChainInfo } from 'utils/chains';
import { Etherscan } from 'utils/etherscan';

interface UseEtherscanInterface {
  chainId?: ChainId;
}
export const useEtherscan = ({ chainId }: UseEtherscanInterface): Etherscan => {
  return useMemo(() => {
    const chain = getChainInfo(chainId ?? defaultChainId);
    return new Etherscan({
      name: chain.explorerName,
      baseURL: chain.explorerBaseURL,
      apiURL: chain.explorerApiURL,
    });
  }, [chainId]);
};
