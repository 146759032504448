import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageContainer } from 'components/page-container';
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  ArrowForwardIcon,
  CheckCircleIcon,
  ExternalLinkIcon,
  NotAllowedIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import { useThemeColors } from 'hooks/use-theme-colors';
import { ChainId, getChainInfo } from 'utils/chains';
import { NavLink, useParams } from 'react-router-dom';
import { useProvider } from 'hooks/use-provider';
import { useEtherscan } from 'hooks/use-etherscan';
import { EtherscanABIError, EtherscanABIResult } from 'utils/etherscan.types';
import { FetchABIAPI } from 'utils/etherscan';

type ContractDebuggerScreenRouteParams = {
  chainId: ChainId;
  address: string;
};

export const ContractDebuggerScreen = () => {
  const { chainId, address } = useParams() as unknown as ContractDebuggerScreenRouteParams;
  const [didFetchAddressType, setDidFetchAddressType] = useState<boolean>(false);
  const [didFetchABI, setDidFetchABI] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<'eoa' | 'contract' | null>(null);
  const [abiResponse, setABIResponse] = useState<FetchABIAPI | null>(null);
  const provider = useProvider({ chainId });
  const etherscan = useEtherscan({ chainId });
  const chainInfo = useMemo(() => {
    return getChainInfo(chainId);
  }, [chainId]);
  const {
    buttonBgColor,
    buttonTextColor,
    containerBgColor,
    containerBorderColor,
    textColor,
    secondaryTextColor,
    textLinkColor,
    textInputBgColor,
    textInputBorderColor,
    textInputPlaceholderColor,
  } = useThemeColors();

  console.log('provider:', provider, 'etherscan', etherscan);

  useEffect(() => {
    if (didFetchAddressType) {
      return;
    }
    setDidFetchAddressType(true);
    const getAddressType = async () => {
      try {
        const bytecode = await provider.getCode(address.toLowerCase());
        // https://docs.ethers.io/v5/single-page/#/v5/api/providers/provider/-%23-Provider-getCode
        const getCodeNoContractResponse = '0x';
        const isContract = bytecode !== getCodeNoContractResponse;
        setAddressType(isContract ? 'contract' : 'eoa');
      } catch (e) {
        console.error(e);
        // if address is totally invalid, can fail with:
        // Uncaught (in promise) Error: bad address checksum (argument="address", value="0x9BdB5fcc80A49640c7872ac089Cc0e00A98451B5", code=INVALID_ARGUMENT, version=address/5.7.0)
        setAddressType('eoa');
      }
    };
    getAddressType();
  }, [address, addressType]);

  useEffect(() => {
    if (didFetchABI || addressType !== 'contract') {
      return;
    }
    setDidFetchABI(true);
    const fetchABI = async () => {
      const response = await etherscan.fetchABI({ contractAddress: address, cache: true });
      console.log('FetchABI:', response);
      setABIResponse(response);
    };
    fetchABI();
  }, [addressType, address]);

  const loadingAddressType = !addressType;
  const loadingABIInfo = !abiResponse;

  return (
    <PageContainer>
      <Heading size="md" mt="10">
        <span style={{ color: secondaryTextColor }}> {chainInfo.name}</span>: {address}
      </Heading>
      <Box paddingTop="3" paddingBottom="5">
        <Link marginRight="5" href={etherscan.getAddressURL(address)} isExternal>
          View on {etherscan.name} <ExternalLinkIcon mx="2px" />
        </Link>
        <NavLink to="/contract-debugger">
          Debug a different contract
          <ArrowForwardIcon mr="2" />
        </NavLink>
      </Box>
      <SimpleGrid columns={2} spacing={10}>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          padding="5"
        >
          <Heading size="md" mb="5">
            Contract info
          </Heading>
          {loadingAddressType && (
            <Center minH={'100px'}>
              <Spinner size="lg" />
            </Center>
          )}
          {!loadingAddressType && addressType !== 'contract' && (
            <Text marginTop="2" color="red.500">
              <WarningTwoIcon marginRight="2" />
              Not a valid smart contract
            </Text>
          )}
          {!loadingAddressType && addressType === 'contract' && (
            <Text marginTop="2">
              <CheckCircleIcon marginRight="2" color="green.500" />
              Valid smart contract
            </Text>
          )}
          {/* Sample unverified contract: 0x750a0cf08337122a4c49ce50d19b8b4969d3ecf2 */}
          {/* Sample verified contract: 0x9BdB5fcc80A49640c7872ac089Cc0e00A98451B6 */}
          {!loadingABIInfo && abiResponse.error && (
            <Text marginTop="2" color="red.500">
              <WarningTwoIcon marginRight="2" />
              {abiResponse.error === EtherscanABIError.CONTRACT_NOT_VERIFIED
                ? 'Contract source code not verified'
                : 'ABI fetch error or rate limit reached'}
            </Text>
          )}
          {!loadingABIInfo && !abiResponse.error && (
            <Text marginTop="2">
              <CheckCircleIcon marginRight="2" color="green.500" />
              Contract source code verified
            </Text>
          )}
        </Box>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          minH={'200px'}
        ></Box>
      </SimpleGrid>
    </PageContainer>
  );
};
