import { ethers } from 'ethers';
import { ChainId, chains } from './chains';

export interface ExtendedProvider extends ethers.providers.JsonRpcProvider {
  chainId: ChainId;
}

const memoizedProviders: { [key in ChainId]?: ExtendedProvider } = {};
export const getProvider = (rawChainId: number | string): ExtendedProvider => {
  const chainId = Number(rawChainId) as ChainId;
  const memoizedProvider = memoizedProviders[chainId];
  if (memoizedProvider) {
    return memoizedProvider;
  }
  const chain = chains[chainId];
  const rpcProvider = new ethers.providers.JsonRpcProvider(chain.rpcURL, {
    name: chain.name,
    chainId,
  });
  const provider = extendProvider(chainId, rpcProvider);
  memoizedProviders[chainId] = provider;
  return provider;
};

const extendProvider = (
  chainId: ChainId,
  provider: ethers.providers.JsonRpcProvider,
): ExtendedProvider => {
  return Object.assign(provider, {
    chainId,
  });
};
