import { useColorModeValue } from '@chakra-ui/react';

export const useThemeColors = (): {
  containerBgColor: string;
  containerBorderColor: string;
  containerHoverBorderColor: string;
  buttonBgColor: string;
  buttonTextColor: string;
  textColor: string;
  secondaryTextColor: string;
  textLinkColor: string;
  textInputBgColor: string;
  textInputBorderColor: string;
  textInputPlaceholderColor: string;
} => {
  const containerBgColor = useColorModeValue('white', 'gray.800');
  const containerBorderColor = useColorModeValue('#ddd', '#364053');
  const containerHoverBorderColor = useColorModeValue('purple.300', 'purple.600');

  const textColor = useColorModeValue('#4e4e60', '#c8c8d6');
  const secondaryTextColor = useColorModeValue('#777', '#c8c8d6');
  const textLinkColor = useColorModeValue('#aa6666', '#b5a3f9');

  const buttonBgColor = useColorModeValue('#444', 'purple.500');
  const buttonTextColor = useColorModeValue('white', 'white');

  const textInputBgColor = useColorModeValue('white', '#312f54');
  const textInputBorderColor = useColorModeValue('#888', '#55519e');
  const textInputPlaceholderColor = useColorModeValue('#8490a0', '#7676ae');

  return {
    containerBgColor,
    containerBorderColor,
    containerHoverBorderColor,
    buttonBgColor,
    buttonTextColor,
    textColor,
    secondaryTextColor,
    textLinkColor,
    textInputBgColor,
    textInputBorderColor,
    textInputPlaceholderColor,
  };
};
