import { ethers } from 'ethers';
import erc20ABI from '../../data/abis/ERC20.json';
import polygonPoolABI from '../../data/abis/dapps/aave/Pool.v3.polygon.json';

export const checkAllowanceCode = ({
  walletAddress,
  tokenContractAddress,
  spenderContractAddress,
}: {
  walletAddress: string;
  tokenContractAddress: string;
  spenderContractAddress: string;
}): string => {
  return `
  // Ethers.js code to check allowance for spender:
  let erc20Contract = new ethers.Contract(
    // token contract address
    '${tokenContractAddress}',
    // partial ERC20 ABI:
    [${JSON.stringify(erc20ABI.find((func) => func.name === 'allowance'))}],
    provider
  );

  await erc20Contract.allowance(
    // wallet address
    '${walletAddress}',
    // spender address
    '${spenderContractAddress}'
  );
  `;
};

export const supplyERC20Code = ({
  poolProxyAddress,
  tokenContractAddress,
  walletAddress,
  amount,
}: {
  poolProxyAddress: string;
  walletAddress: string;
  tokenContractAddress: string;
  amount: ethers.BigNumber;
}): string => {
  return `
  // Ethers.js code to supply ERC20 to Aave Pool:
  let poolContract = new ethers.Contract(
    // pool proxy contract address
    '${poolProxyAddress}',
    // partial pool ABI:
    [${JSON.stringify(polygonPoolABI.find((func) => func.name === 'supply'))}],
    provider
  );

  let estimatedGas = await poolContract.estimateGas.supply(
    // token contract address
    '${tokenContractAddress}',
    // amount
    '${amount.toString()}',
    // wallet address
    '${walletAddress}',
    // referral code (not used)
    0
  );

  let unsignedTx = await poolContract.populateTransaction.supply(
    // token contract address
    '${tokenContractAddress}',
    // amount
    '${amount.toString()}',
    // wallet address
    '${walletAddress}',
    // referral code (not used)
    0
  )
  `;
};
