import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CodeBlock, CopyBlock, nord } from 'react-code-blocks';
import { PageContainer } from 'components/page-container';
import {
  Box,
  Button,
  Center,
  Code,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArrowForwardIcon,
  CheckCircleIcon,
  CheckIcon,
  ExternalLinkIcon,
  NotAllowedIcon,
  WarningTwoIcon,
} from '@chakra-ui/icons';
import { useThemeColors } from 'hooks/use-theme-colors';
import { ChainId, getChainInfo } from 'utils/chains';
import { NavLink, useParams } from 'react-router-dom';
import { useProvider } from 'hooks/use-provider';
import { useEtherscan } from 'hooks/use-etherscan';
import { isValidEvmAddress } from 'utils/evm-address.utils';
import { Contract, ethers } from 'ethers';
import { ERC20__factory } from 'types/abis';
import { checkAllowanceCode, supplyERC20Code } from './codegen.utils';

type AaveChainIds = ChainId.MATIC;
interface AaveContracts {
  poolProxy: string;
  wethGateway: string;
  tokens: Array<{ symbol: string; contractAddress: string | null }>;
  aTokens: Array<{ symbol: string; contractAddress: string; wethGateway: boolean }>;
}
const aaveContractsMap: { [key in AaveChainIds]: AaveContracts } = {
  // https://docs.aave.com/developers/deployed-contracts/v3-testnet-addresses
  // [ChainId.ETH_GOERLI]: {
  //   // Functionality:
  //   poolProxy: '0x368EedF3f56ad10b9bC57eed4Dac65B26Bb667f6',
  //   wethGateway: '0xd5B55D3Ed89FDa19124ceB5baB620328287b915d',
  //   // Tokens:
  //   wethAToken: '0x27B4692C93959048833f40702b22FE3578E77759',
  // },
  // https://docs.aave.com/developers/deployed-contracts/v3-mainnet/polygon
  [ChainId.MATIC]: {
    // Functionality:
    poolProxy: '0x794a61358D6845594F94dc1DB02A252b5b4814aD',
    wethGateway: '0x1e4b7A6b903680eab0c5dAbcb8fD429cD2a9598c',
    // https://docs.aave.com/developers/deployed-contracts/v3-mainnet/polygon#tokens
    tokens: [
      {
        symbol: 'MATIC',
        contractAddress: null, // native
      },
      {
        symbol: 'WMATIC',
        contractAddress: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
      },
      {
        symbol: 'DAI',
        contractAddress: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      },
    ],
    aTokens: [
      {
        symbol: 'aWMATIC',
        contractAddress: '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97',
        wethGateway: true,
      },
      {
        symbol: 'aDAI',
        contractAddress: '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
        wethGateway: false,
      },
    ],
  },
};

interface TokenBalance {
  isSupplyable: boolean;
  symbol: string;
  balance: ethers.BigNumber;
  decimals: number;
  // These two are N/A for native token:
  contract: Contract | null;
  allowance?: {
    amount: ethers.BigNumber;
    contractAddress: string;
    contractName: string;
  };
}

type ScreenRouteParams = {
  chainId: ChainId;
  address: string;
};

export const AaveV3DebugScreen = () => {
  const {
    isOpen: isCodeModalOpen,
    onOpen: onOpenCodeModal,
    onClose: onCloseCodeModal,
  } = useDisclosure();
  const [codeModalContents, setCodeModalContents] = useState<string>('');

  const { chainId: paramsChainId, address: paramsAddress } =
    useParams() as unknown as ScreenRouteParams;
  const [inputAddress, setInputAddress] = useState<string | null>(paramsAddress ?? null);
  const [chainId, _setChainId] = useState<ChainId>(paramsChainId ?? ChainId.MATIC);
  const [loadingSupplyableBalances, setLoadingSupplyableBalances] = useState<boolean>(false);
  const [tokenBalances, setTokenBalances] = useState<Array<TokenBalance>>([]);
  const provider = useProvider({ chainId });
  const etherscan = useEtherscan({ chainId });
  const chainInfo = useMemo(() => {
    return getChainInfo(chainId);
  }, [chainId]);
  const aaveContracts = useMemo(() => {
    return aaveContractsMap[chainId as ChainId.MATIC];
  }, [chainId]);
  const {
    buttonBgColor,
    buttonTextColor,
    containerBgColor,
    containerBorderColor,
    textColor,
    secondaryTextColor,
    textLinkColor,
    textInputBgColor,
    textInputBorderColor,
    textInputPlaceholderColor,
  } = useThemeColors();

  const onChangeInputAddress = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.trim();

    // If input is cleared, just reset all state:
    if (!rawValue) {
      setInputAddress(null);
      return;
    }
    setInputAddress(rawValue);
  }, []);

  const walletAddress: string | null = useMemo(() => {
    if (isValidEvmAddress(inputAddress)) {
      return inputAddress;
    }
    return null;
  }, [inputAddress]);

  const isInvalidInputAddress = !!inputAddress && !walletAddress;

  const openCodeModal = useCallback(({ code }: { code: string }) => {
    setCodeModalContents(code);
    onOpenCodeModal();
  }, []);

  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    const fetchTokens = async () => {
      setLoadingSupplyableBalances(true);
      const balances: Array<TokenBalance> = [];
      for (const token of aaveContracts.tokens) {
        console.log('todo:', token);
        if (!token.contractAddress) {
          const balance = await provider.getBalance(walletAddress);
          balances.push({
            ...token,
            isSupplyable: balance.gt(0),
            contract: null,
            balance,
            decimals: 18,
          });
        } else {
          const contract = ERC20__factory.connect(token.contractAddress, provider);
          const balance = await contract.balanceOf(walletAddress);
          const decimals = await contract.decimals();
          const allowanceContract = aaveContracts.poolProxy;
          const allowance = await contract.allowance(walletAddress, aaveContracts.poolProxy);
          balances.push({
            ...token,
            isSupplyable: balance.gt(0) && allowance.gt(0),
            contract,
            balance,
            decimals,
            allowance: {
              amount: allowance,
              contractAddress: allowanceContract,
              contractName: 'Pool v3 Proxy',
            },
          });
        }
        setTokenBalances(balances);
      }
      console.log('balances', balances);
      setTokenBalances(balances);
      setLoadingSupplyableBalances(false);
    };
    fetchTokens();
  }, [walletAddress, provider]);

  return (
    <PageContainer>
      <Heading size="md" mt="10">
        <span style={{ color: secondaryTextColor }}> {chainInfo.name}</span>: Aave v3 Debugger
      </Heading>
      <FormControl mt="5" mb="5" maxW="600px" isInvalid={isInvalidInputAddress}>
        <FormLabel>Wallet address:</FormLabel>
        <InputGroup size="lg">
          <Input
            size="lg"
            autoFocus
            autoCorrect="false"
            autoComplete="off"
            placeholder="0x..."
            name="address"
            type="text"
            data-lpignore="true"
            value={inputAddress || ''}
            onChange={onChangeInputAddress}
            backgroundColor={textInputBgColor}
            borderColor={textInputBorderColor}
            _placeholder={{ color: textInputPlaceholderColor }}
          />
          {walletAddress && (
            <InputRightElement>
              <CheckIcon color="green.500" />
            </InputRightElement>
          )}
          {isInvalidInputAddress && (
            <InputRightElement>
              <WarningTwoIcon color="red.500" />
            </InputRightElement>
          )}
        </InputGroup>
        {isInvalidInputAddress && (
          <FormErrorMessage marginTop="2">
            <WarningTwoIcon marginRight="2" />
            Invalid EVM address
          </FormErrorMessage>
        )}
      </FormControl>
      {walletAddress && (
        <Box paddingBottom="3">
          <NavLink to={`/aave-v3-debug/${chainId}/${walletAddress}`}>
            Permalink <ExternalLinkIcon mx="2px" />
          </NavLink>
          <Link
            marginLeft="5"
            marginRight="5"
            href={etherscan.getAddressURL(walletAddress)}
            isExternal
            color="blue.500"
          >
            View on {etherscan.name} <ExternalLinkIcon mx="2px" />
          </Link>
        </Box>
      )}
      <SimpleGrid marginTop="3" columns={2} spacing={10}>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          padding="5"
        >
          <Heading size="lg" mb="5">
            Supplyable token balances
          </Heading>
          {loadingSupplyableBalances && (
            <Center minH="200px">
              <Spinner size="lg" />
            </Center>
          )}
          {tokenBalances.map((tokenBalance) => (
            <Box key={tokenBalance.symbol} paddingBottom="6">
              <Heading size="md" pb="2">
                {tokenBalance.symbol} ({tokenBalance.contract ? 'ERC-20 token' : 'Native token'})
                {tokenBalance.isSupplyable ? (
                  <CheckCircleIcon ml="2" color="green.500" />
                ) : (
                  <WarningTwoIcon ml="2" color="yellow.600" />
                )}
              </Heading>
              {tokenBalance.contract && (
                <Text>
                  Token contract:{' '}
                  <Link
                    href={etherscan.getAddressURL(tokenBalance.contract.address, { code: true })}
                    isExternal
                    color="blue.500"
                  >
                    {tokenBalance.contract.address} <ExternalLinkIcon />
                  </Link>
                </Text>
              )}
              <Text>Decimals: {tokenBalance.decimals.toString()}</Text>
              <Text>Balance: {tokenBalance.balance.toString()}</Text>
              <Text>
                Balance (formatted):{' '}
                {ethers.utils.formatUnits(tokenBalance.balance, tokenBalance.decimals)}
              </Text>
              {tokenBalance.balance.isZero() && (
                <Text color="yellow.600">
                  <WarningTwoIcon />
                  No balance available to supply.
                </Text>
              )}
              {tokenBalance.allowance ? (
                <>
                  <Text>
                    Allowance contract:{' '}
                    <Link
                      href={etherscan.getAddressURL(tokenBalance.allowance.contractAddress, {
                        code: true,
                      })}
                      isExternal
                      color="blue.500"
                    >
                      {tokenBalance.allowance.contractName} (
                      {tokenBalance.allowance.contractAddress.substring(0, 5)}...){' '}
                      <ExternalLinkIcon />
                    </Link>
                  </Text>
                  <Text>Allowance: {tokenBalance.allowance.amount.toString()}</Text>
                  {tokenBalance.allowance.amount.isZero() && (
                    <Text color="yellow.600">
                      <WarningTwoIcon />
                      Token must be approved first before it can be supplied.
                    </Text>
                  )}
                </>
              ) : (
                <Text>Allowance: N/A for native token</Text>
              )}
              <Text fontWeight="bold" mt="3" mb="3">
                Ethers.js code samples:
              </Text>
              {walletAddress && tokenBalance.allowance && tokenBalance.contract && (
                <HStack spacing="2">
                  <Button
                    onClick={() =>
                      openCodeModal({
                        code: checkAllowanceCode({
                          tokenContractAddress: tokenBalance.contract?.address as string,
                          spenderContractAddress: tokenBalance.allowance?.contractAddress as string,
                          walletAddress,
                        }),
                      })
                    }
                  >
                    Check Allowance
                  </Button>
                  <Button
                    onClick={() =>
                      openCodeModal({
                        code: supplyERC20Code({
                          poolProxyAddress: tokenBalance.allowance?.contractAddress as string,
                          tokenContractAddress: tokenBalance.contract?.address as string,
                          walletAddress,
                          amount: tokenBalance.balance,
                        }),
                      })
                    }
                  >
                    Supply
                  </Button>
                </HStack>
              )}
            </Box>
          ))}
        </Box>
        <Box
          backgroundColor={containerBgColor}
          borderColor={containerBorderColor}
          borderWidth="1px"
          borderRadius="md"
          minH={'200px'}
        ></Box>
      </SimpleGrid>
      <Modal size="3xl" isOpen={isCodeModalOpen} onClose={onCloseCodeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Ethers.js code sample</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CopyBlock theme={nord} language="javascript" text={codeModalContents} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </PageContainer>
  );
};
