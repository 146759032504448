import { ABIEntry } from '../types/abi-json.types';

export interface EtherscanParsedResponse<T> {
  error?: string;
  result: T;
}

// *scan (Etherscan.io family) of explorers:
// https://info.etherscan.com/api-return-errors/
export interface EtherscanApiResponse<T> {
  // 1 = OK, 0 = NOT OK
  status: '1' | '0';
  message: string | void;
  // string/array/object
  result: T;
}

export type EtherscanABIResult = Array<ABIEntry>;
export enum EtherscanABIError {
  CONTRACT_NOT_VERIFIED = 'CONTRACT_NOT_VERIFIED',
  RATE_LIMIT_REACHED = 'RATE_LIMIT_REACHED',
}
