import { getInfuraProjectId } from './env';

export enum ChainId {
  ETH = 1,
  // ETH_GOERLI = 5,
  MATIC = 137,
}

interface Chain {
  id: ChainId;
  name: string;
  gasToken: string;
  rpcURL: string;
  explorerName: string;
  explorerBaseURL: string;
  explorerApiURL: string;
}

type Chains = { [chainId in ChainId]: Chain };

export const chains: Chains = {
  [ChainId.ETH]: {
    id: ChainId.ETH,
    name: 'Ethereum',
    gasToken: 'ETH',
    rpcURL: `https://mainnet.infura.io/v3/${getInfuraProjectId()}`,
    explorerName: 'Etherscan',
    explorerBaseURL: 'https://etherscan.io',
    explorerApiURL: 'https://api.etherscan.io',
  },
  [ChainId.MATIC]: {
    id: ChainId.MATIC,
    name: 'Polygon',
    gasToken: 'MATIC',
    rpcURL: 'https://polygon-rpc.com',
    explorerName: 'Polygonscan',
    explorerBaseURL: 'https://polygonscan.com',
    explorerApiURL: 'https://api.polygonscan.com',
  },
};

export const defaultChainId = ChainId.ETH;

export const getChainInfo = (chainId: number | ChainId): Chain => {
  return chains[chainId as ChainId];
};
