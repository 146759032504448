import { useMemo } from 'react';
import { ChainId, defaultChainId } from 'utils/chains';
import { ExtendedProvider, getProvider } from 'utils/providers';

interface UseProviderInterface {
  chainId?: ChainId;
}
export const useProvider = ({ chainId }: UseProviderInterface): ExtendedProvider => {
  return useMemo(() => {
    return getProvider(chainId ?? defaultChainId);
  }, [chainId]);
};
