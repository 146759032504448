import React from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { theme } from './theme';
import { DashboardScreen } from 'screens/dashboard';
import { AboutScreen } from 'screens/about';
import { ContractDebuggerScreen } from 'screens/contract-debugger';
import { ContractDebuggerInputScreen } from 'screens/contract-debugger-input';
import { AaveV3DebugScreen } from 'screens/aave-v3-debug';

export const App = () => {
  return (
    <Providers>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Routes>
        <Route path="/" element={<DashboardScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/aave-v3-debug" element={<AaveV3DebugScreen />} />
        <Route path="/aave-v3-debug/:chainId/:address" element={<AaveV3DebugScreen />} />
        <Route path="/contract-debugger" element={<ContractDebuggerInputScreen />} />
        <Route path="/contract-debugger/:chainId/:address" element={<ContractDebuggerScreen />} />
      </Routes>
    </Providers>
  );
};

const Providers = ({ children }: React.PropsWithChildren) => {
  return (
    <BrowserRouter>
      <ChakraProvider resetCSS theme={theme}>
        {children}
      </ChakraProvider>
    </BrowserRouter>
  );
};
